import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import CheckIcon from "../Ui/Icons/homePage/CheckIcon";
import { Button } from "../Ui/Button";
import { useRouter } from "next/router";

export const Pricing = () => {
  const { __T } = useTranslator();
  const router = useRouter();

  const { locale: activeLocale } = router;
  const handelOnClick = () => {
    window.location.href = "https://company.kibreet.io/en/register";
  };
  return (
    <>
      <section
        className="text-center mb-10  md:pt-12 container mx-auto"
        id="pricing"
      >
        <div className="rtl:flex-col-reverse rtl:flex my-11">
          <h3 className="text-black-100 md:text-5xl text-3xl font-extrabold underline decoration-brand-100">
            {__T("Companies")}
          </h3>
          <h3 className="text-black-100 md:text-5xl text-3xl font-extrabold">
            {__T("Packages")}
          </h3>
        </div>
        <div className="grid md:justify-center gap-4 md:gap-17 sm:grid-cols-2 lg:grid-cols-4 p-4">
          <div className="flex flex-col items-start py-4 md:py-11 shadow-box rounded-xl">
            <div className="rounded-xl px-4 md:px-11 pb-4">
              <h4 className="text-black-100 text-xl mb-3 text-start">
                {__T("Silver")}
              </h4>
              <p className="text-gray-15 text-base mb-3">
                <span className="text-brand-100 text-2xl font-extrabold">
                  60 {__T("SR")}
                </span>
                /{__T("year")}
              </p>
              <p className="text-gray-15 text-base">{__T("For one vehicle")}</p>
            </div>
            <div className="space-y-1 text-gray-15 text-md text-start border-t border-gray-5  w-full ">
              <ul className="my-1 leading-extra-loose ltr:text-gray-15 text-md p-4 md:px-11 ltr:text-left">
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Regular follow-up")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Services analytics")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Tax E-Invoices")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Digital wallet")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("Number of vehicles {number}", { number: "6:10" })}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("NFC chip price : {price} SAR", { price: 25 })}
                </li>
              </ul>
            </div>
            <div className="w-full  px-4 md:px-11">
              <Button
                buttonStyle="outlinePrimary"
                onClick={handelOnClick}
                rounded="xl"
                size="2xl"
                type="button"
                className="w-full"
              >
                {__T("Choose Now")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start py-4 md:py-11 shadow-box rounded-xl">
            <div className="rounded-xl px-4 md:px-11 pb-4">
              <h4 className="text-black-100 text-xl mb-3 text-start">
                {__T("Gold")}
              </h4>
              <p className="text-gray-15 text-base mb-3">
                <span className="text-brand-100 text-2xl font-extrabold">
                  55 {__T("SR")}
                </span>
                /{__T("year")}
              </p>
              <p className="text-gray-15 text-base">{__T("For one vehicle")}</p>
            </div>
            <div className="space-y-1 text-gray-15 text-md text-start border-t border-gray-5  w-full ">
              <ul className="my-1 leading-extra-loose ltr:text-gray-15 text-md p-4 md:px-11 ltr:text-left">
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Regular follow-up")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Services analytics")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Tax E-Invoices")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Digital wallet")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("Number of vehicles {number}", { number: "11:30" })}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("NFC chip price : {price} SAR", { price: 25 })}
                </li>
              </ul>
            </div>
            <div className="w-full px-4 md:px-11">
              <Button
                buttonStyle="outlinePrimary"
                rounded="xl"
                size="2xl"
                type="button"
                className="w-full"
                onClick={handelOnClick}
              >
                {__T("Choose Now")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start py-4 md:py-11 shadow-box rounded-xl">
            <div className="rounded-xl px-4 md:px-11 pb-4">
              <h4 className="text-black-100 text-xl mb-3 text-start">
                {__T("Platinum")}
              </h4>
              <p className="text-gray-15 text-base mb-3">
                <span className="text-brand-100 text-2xl font-extrabold">
                  50 {__T("SR")}
                </span>
                /{__T("year")}
              </p>
              <p className="text-gray-15 text-base">{__T("For one vehicle")}</p>
            </div>
            <div className="space-y-1 text-gray-15 text-md text-start border-t border-gray-5  w-full ">
              <ul className="my-1 leading-extra-loose ltr:text-gray-15 text-md p-4 md:px-11 ltr:text-left">
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Regular follow-up")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Services analytics")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Tax E-Invoices")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Digital wallet")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("Number of vehicles {number}", { number: "31:100" })}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("NFC chip price : {price} SAR", { price: 25 })}
                </li>
              </ul>
            </div>
            <div className="w-full px-4 md:px-11">
              <Button
                buttonStyle="outlinePrimary"
                rounded="xl"
                size="2xl"
                type="button"
                className="w-full"
                onClick={handelOnClick}
              >
                {__T("Choose Now")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start shadow-box rounded-xl py-4 md:py-11">
            <div className="rounded-xl px-4 md:px-11 pb-4">
              <h4 className="text-black-100 text-xl mb-3 text-start">
                {__T("Titanium")}
              </h4>
              <p className="text-gray-15 text-base mb-3">
                <span className="text-brand-100 text-2xl font-extrabold">
                  50 {__T("SR")}
                </span>
                /{__T("year")}
              </p>
              <p className="text-gray-15 text-base">{__T("For one vehicle")}</p>
            </div>

            {/* <h3 className="my-3 text-xl text-black-100 font-semibold">Optimal Solution</h3> */}
            <div className="space-y-1 text-gray-15 text-md text-start border-t border-gray-5  w-full ">
              <ul className="my-1 leading-extra-loose ltr:text-gray-15 text-md p-4 md:px-11 ltr:text-left">
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Regular follow-up")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Services analytics")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Tax E-Invoices")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                    />
                  </span>
                  {__T("Digital wallet")}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("Number of vehicles {number}", { number: "101:1000" })}
                </li>
                <li>
                  <span>
                    {" "}
                    <CheckIcon
                      width={14}
                      height={14}
                      className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                    />
                  </span>
                  {__T("NFC chip price : {price} SAR", { price: 25 })}
                </li>
              </ul>
            </div>
            <div className="w-full px-4 md:px-11">
              <Button
                buttonStyle="outlinePrimary"
                rounded="xl"
                size="2xl"
                type="button"
                className="w-full"
                onClick={handelOnClick}
              >
                {__T("Choose Now")}
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="text-center mb-10 pt-5 bg-[#F6FCFE] ">
        <div className="container mx-auto ltr:md:mr-0 rtl:md:ml-0">
          <div className="w-full">
            <div className="w-full rtl:flex rtl:flex-col-reverse p-4 md:p-0 mb-11">
              <h3 className="text-black-100 md:text-5xl text-3xl font-extrabold text-start underline decoration-brand-100">
                {__T("Individuals")}
              </h3>
              <h3 className="md:text-5xl text-3xl font-extrabold text-start text-brand-100">
                {" "}
                {__T("Package")}
              </h3>
            </div>
            <div className="w-full md:flex">
              <div className="md:w-1/5 w-full p-4 md:p-0">
                <div className="flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl bg-[#67fb420f]">
                  <div className="rounded-xl text-start">
                    <h4 className="text-brand-100 text-2xl mb-3 font-extrabold">
                      {__T("FREE")}
                    </h4>
                    <p className="text-gray-15"> {__T("For All vehicles")}</p>
                    {/* <p className="text-gray-15 text-base mb-3">
                                            <span className="text-brand-100 text-2xl font-extrabold">$35</span>/month</p> */}
                  </div>
                  {/* <p className="text-gray-15 text-base">
                                        7-day free trial
                                    </p> */}
                  {/* <h3 className="my-3 text-xl text-black-100 font-semibold">Optimal Solution</h3> */}
                  <div className="space-y-1 text-gray-15 text-md text-start">
                    <ul className="my-5 leading-extra-loose text-start">
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                          />
                        </span>
                        {__T("Regular follow-up")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                          />
                        </span>
                        {__T("Services analytics")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                          />
                        </span>
                        {__T("Tax E-Invoices")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100"
                          />
                        </span>
                        {__T("Digital wallet")}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            color="#fbbf42"
                            className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                          />
                        </span>
                        {__T("Number of vehicles {number}", { number: "1:5" })}
                      </li>
                      <li>
                        <span>
                          {" "}
                          <CheckIcon
                            width={14}
                            height={14}
                            color="#fbbf42"
                            className="inline-block ltr:mr-1 rtl:ml-1 text-brand-100"
                          />
                        </span>
                        {__T("NFC chip price : {price} SAR", { price: 25 })}
                      </li>
                    </ul>
                  </div>
                  <div className="w-full">
                    <Button
                      buttonStyle="outlineSecondary"
                      onClick={handelOnClick}
                      rounded="xl"
                      size="2xl"
                      type="button"
                      className="w-full"
                    >
                      {__T("Choose Now")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="md:w-4/5 w-full ">
                <div className="w-full mx-auto">
                  <h3 className="font-bold text-6xl md:w-2/3 mx-auto">
                    {__T("+1000 Service Center")} {__T("&")}{" "}
                    <span className="text-brand-100">
                      {__T("Service vans")}
                    </span>{" "}
                  </h3>
                  <p className="p-3 font-thin md:w-2/3 mx-auto">
                    {__T(
                      "covering all parts of the KSA to provide oil change services and motor oil filters with The highest quality and professional technicians."
                    )}
                  </p>
                  <div className="bg-[url('../public/images/poster.png')] w-full h-[400px] bg-no-repeat bg-cover md:bg-center bg-top"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
